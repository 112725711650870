import React, { useState, useEffect } from "react";
import { ModuleNamesList } from "../../../core/lists/ModuleNamesList";
import TranslationService from "../../../core/services/TranslationService";
import { Formik, Form } from "formik";
import FormGroupField from "../../../core/components/Forms/FormGroupField";
import * as yup from "yup";
import { ICompanyFormProps } from "../types/ICompanyFormProps";
import StepListWizardButtons from "../../../modules/StepListWizard/StepListWizardButtons";
import CreditorRegistrationApiClient from "../CreditorRegistrationApiClient";
import { Button, BUTTON_VARIANTS } from "core/components/Button/Button";
import useLocalStorageState from "../../../core/hooks/useLocalStorageState";

const phoneRegex = /^\+{1}\d{8,14}/;

const CompanyForm = (props: ICompanyFormProps) => {
  const [lastOrgNumberExistingState, setLastOrgNumberExistingState] = useState<boolean>(true);
  const [organizationNumberSpinner, setOrganizationNumberSpinner] = useState<boolean>(false);
  const [orgNumberFieldFocused, setOrgNumberFieldFocused] = useState<boolean>(false);
  const [prevOrgNumber, setPrevOrgNumber] = useState<string>("");
  const [initialValues, handleUpdateForm] = useLocalStorageState({
    key: "CompanyForm",
    value: props.companyForm
  });

  const validationMessages = {
    email: TranslationService.translate("ValidationMessageIncorrentEmailFormat"),
    incorrectPhoneNoPhormat: TranslationService.translate("ValidationMessageIncorrectPhoneNoFormat"),
    max: TranslationService.translate("ValidationMessageValueIsTooLong"),
    min: TranslationService.translate("ValidationMessageValueIsTooShort"),
    numberFormat: TranslationService.translate("ValidationMessageIncorrectNumberFormat"),
    orgNumberExists: TranslationService.translateModule("ValidationMessageCompanyNoExists", ModuleNamesList.CreditorRegistration),
    required: TranslationService.translate("ValidationMessageRequiredField"),
    url: TranslationService.translate("ValidationMessageIncorrectUrlFormat"),
    positive: TranslationService.translate("ValidationMessagePositiveNumber")
  };

  const validationSchema = yup.object().shape({
    organizationNumber: yup.string()
      .matches(/^[0-9]*$/, validationMessages.numberFormat)
      .min(5, validationMessages.min)
      .max(13, validationMessages.max)
      .test("org-no-exists", validationMessages.orgNumberExists, async (value) => {
        if (value && !orgNumberFieldFocused && prevOrgNumber !== value) {
          const orgNoExists = await orgNumberExists(value);

          setPrevOrgNumber(value);
          setLastOrgNumberExistingState(!orgNoExists);
        }

        return lastOrgNumberExistingState;
      })
      .required(validationMessages.required),
    organizationName: yup.string().max(40, validationMessages.max).required(validationMessages.required),
    industryCode: yup.string().max(20, validationMessages.max),
    generalInformation: yup.object().shape({
      website: yup.string().max(30, validationMessages.max).url(validationMessages.url),
      email: yup.string().max(50, validationMessages.max).email(validationMessages.email).required(validationMessages.required),
      phoneNumber: yup.string().max(20, validationMessages.max).required(validationMessages.required).matches(phoneRegex, validationMessages.incorrectPhoneNoPhormat)
    }),
    contactDetails: yup.object().shape({
      firstName: yup.string().max(50, validationMessages.max).required(validationMessages.required),
      lastName: yup.string().max(80, validationMessages.max).required(validationMessages.required),
      phoneNumber: yup.string().max(15, validationMessages.max).required(validationMessages.required).matches(phoneRegex, validationMessages.incorrectPhoneNoPhormat),
      email: yup.string().max(50, validationMessages.max).email(validationMessages.email).required(validationMessages.required)
    }),
    address: yup.object().shape({
      street: yup.string().max(40, validationMessages.max),
      city: yup.string().max(30, validationMessages.max).required(validationMessages.required),
      postalCode: yup.string().test("len", validationMessages.max, val => val === undefined || val.toString().length <= 10).required(validationMessages.required),
      address2: yup.string().max(50, validationMessages.max)
    })
  });

  const orgNumberExists = async (organizationNumber: string): Promise<boolean> => {
    setOrganizationNumberSpinner(true);
    const companyNumberExists = await CreditorRegistrationApiClient.ValidateIsCompanyNumberExists(organizationNumber);
    setOrganizationNumberSpinner(false);
    return companyNumberExists;
  };

  const handleOnValidateForm = (values: any) => {
    handleUpdateForm(values);
  };

  useEffect(() => {
    if (sessionStorage.getItem('CompanyFormFilled') === null) {
      handleUpdateForm(props.companyForm, true);
    }
  }, [props.companyForm]);

  return (
    <div className="step-content">
      <Formik
        validateOnBlur
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          props.onCompanyDataFilled(values);
        }}
        validationSchema={validationSchema}
        validate={handleOnValidateForm}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur
          }) => {
          return (
            <Form>
              <header className="step-header">
                <h2
                  className="step-title">{TranslationService.translateModule("CompanyHeader", ModuleNamesList.CreditorRegistration)}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: TranslationService.translateModule("CompanyDescription", ModuleNamesList.CreditorRegistration) }} />
              </header>

              <div className="row c-control">
                <FormGroupField
                  errors={errors}
                  fieldName="organizationNumber"
                  handleChange={(e: any) => {
                    setOrgNumberFieldFocused(true);
                    handleChange(e);
                  }}
                  label={TranslationService.translateModule("CompanyOrganizationNumber", ModuleNamesList.CreditorRegistration)}
                  touched={touched}
                  handleBlur={(e) => {
                    setOrgNumberFieldFocused(false);
                    handleBlur(e);
                  }}
                  inputContainerClass="col-sm-4"
                  loadingSpinner={organizationNumberSpinner}
                  required={true}
                  hideFormGroupDiv={true}
                  disabled={props.prefilledFields}
                  value={values.organizationNumber}
                />

                {props.configuration.isBrregEnabled &&
                <Button
                  type="button"
                  className="c-control__btn"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  disabled={!values.organizationNumber}
                  onClick={() => props.onFetchCompanyData(values.organizationNumber)}
                >
                  {TranslationService.translateModule("FetchFromBrreg", ModuleNamesList.CreditorRegistration)}
                </Button>
                }
              </div>

              <FormGroupField
                errors={errors}
                fieldName="organizationName"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyOrganizationName", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                required={true}
                disabled={props.prefilledFields}
                value={values.organizationName}
              />
              <FormGroupField
                errors={errors}
                fieldName="industryCode"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyIndustryCode", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-3"
                disabled={props.prefilledFields}
                value={values.industryCode}
              />

              <hr />

              <header className="step-header">
                <h2
                  className="step-title normal">{TranslationService.translateModule("CompanyAddressHeader", ModuleNamesList.CreditorRegistration)}</h2>
              </header>

              <FormGroupField
                errors={errors}
                fieldName="address.street"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyAddressStreet", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                disabled={props.prefilledFields}
                value={values.address.street}
              />
              <FormGroupField
                errors={errors}
                fieldName="address.address2"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyAddressAddress2", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                disabled={props.prefilledFields}
                value={values.address.address2}
              />
              <FormGroupField
                errors={errors}
                fieldName="address.postalCode"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyAddressPostalCode", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                disabled={props.prefilledFields}
                value={values.address.postalCode}
              />
              <FormGroupField
                errors={errors}
                fieldName="address.city"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyAddressCity", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                disabled={props.prefilledFields}
                value={values.address.city}
              />

              <hr />

              <header className="step-header">
                <h2
                  className="step-title normal">{TranslationService.translateModule("CompanyGeneralInformationHeader", ModuleNamesList.CreditorRegistration)}</h2>
              </header>

              <FormGroupField
                errors={errors}
                fieldName="generalInformation.website"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyWebsite", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                value={values.generalInformation.website}
              />
              <FormGroupField
                errors={errors}
                fieldName="generalInformation.email"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyEmail", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                value={values.generalInformation.email}
              />
              <FormGroupField
                errors={errors}
                fieldName="generalInformation.phoneNumber"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyPhoneNo", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                placeholder={TranslationService.translate("PhoneNumberFieldPlaceholder")}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                value={values.generalInformation.phoneNumber}
              />

              <hr />

              <header className="step-header">
                <h2
                  className="step-title normal">{TranslationService.translateModule("CompanyMainContactHeader", ModuleNamesList.CreditorRegistration)}</h2>
              </header>

              <FormGroupField
                errors={errors}
                fieldName="contactDetails.firstName"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyCPFirstName", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                value={values.contactDetails.firstName}
              />
              <FormGroupField
                errors={errors}
                fieldName="contactDetails.lastName"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyCPLastName", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                value={values.contactDetails.lastName}
              />
              <FormGroupField
                errors={errors}
                fieldName="contactDetails.phoneNumber"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyCPPhoneNo", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                placeholder={TranslationService.translate("PhoneNumberFieldPlaceholder")}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                value={values.contactDetails.phoneNumber}
              />

              <FormGroupField
                errors={errors}
                fieldName="contactDetails.email"
                handleChange={handleChange}
                label={TranslationService.translateModule("CompanyCPEmail", ModuleNamesList.CreditorRegistration)}
                touched={touched}
                handleBlur={handleBlur}
                inputContainerClass="col-sm-7"
                required={true}
                value={values.contactDetails.email}
              />

              <hr />

              <StepListWizardButtons
                rightButtonText={<>{TranslationService.translate("Next")}</>}
                rightButtonOnClick={() => {
                  sessionStorage.setItem('CompanyFormFilled', "true");
                }}
              />
            </Form>);
        }
        }
      </Formik>
    </div>
  );
};

export default CompanyForm;
