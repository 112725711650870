import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter, RouteComponentProps } from 'react-router';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import Loader from '../../core/components/Loading/Loader';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IStore } from '../../reducers/IStore';
import './DebtorDetails.scss';
import DebtorDetailsService from './services/DebtorDetailsService';
import { emptyDebtorDetails, IDebtorDetails } from './types/IDebtorDetails';
import { Button, BUTTON_VARIANTS, BUTTON_SIZE } from '../../core/components/Button/Button';

interface IState {
  debtorDetails: IDebtorDetails;
  hasAccessToModule: boolean;
  isDataLoaded: boolean;
}

interface IProps extends IExtendedModuleProps, RouteComponentProps {
  currency: string;
  locale: string;
}

class DebtorDetails extends Component<IProps, IState> {
  state: IState = {
    debtorDetails: emptyDebtorDetails,
    hasAccessToModule: false,
    isDataLoaded: false,
  };
  module: any = flatModuleOptions<any>(this.props.module);

  async componentDidMount() {
    try {
      await this.loadDebtorDetails();
    } catch (err) {
      if (err.response && err.response.status === 403) {
        this.setState({
          hasAccessToModule: false,
        });
      }
    }

    this.setState({
      isDataLoaded: true,
    });
  }

  render() {
    if (!this.hasRequiredParams()) {
      return <Redirect to="/error" />;
    }

    if (!this.state.hasAccessToModule && this.state.isDataLoaded) {
      return <NoPermissionsComponent />;
    }

    const scoringRate = this.state.debtorDetails.scoringRate;
    const redValue = this.module['ScoringRateRedValue'];
    const greenValue = this.module['ScoringRateGreenValue'];
    const showDebtorId = this.module['ShowDebtorId'] && this.module['ShowDebtorId'] === 'true';
    const scoringProps = {
      score: scoringRate ? parseInt(scoringRate, 10) : undefined,
      redValue: redValue ? parseInt(redValue, 10) : 0,
      greenValue: greenValue ? parseInt(greenValue, 10) : 0,
    };

    return (
      <article className="l-module l-module--full-height debtor-details-module mt-3">
        <section
          className={`l-module__section ${
            !this.state.isDataLoaded ? 'l-module__section--loading' : ''
          }`}
        >
          {!this.state.isDataLoaded && <Loader opacity={0.5} />}

          <div className="l-debtor">
            <div className="l-debtor__main-details">
              <div className="l-debtor__table c-table c-table--col-2">
                <div className="l-debtor__table-row l-debtor__table-row--font-x2 c-table__row">
                  <div className="c-table__cell font--color-1 font--700">
                    {TranslationService.translateModule('Debtor', this.props.module.name)}
                  </div>
                  <div className="c-table__cell font--color-3 font--700">
                    {this.state.debtorDetails.name}
                  </div>
                </div>

                {this.state.debtorDetails.scoringRate && (
                  <div className="l-debtor__table-row l-debtor__table-row--font-x2 c-table__row">
                    <div className="c-table__cell">
                      {TranslationService.translateModule('ScoringRate', this.props.module.name)}
                    </div>
                    <div className="c-table__cell">
                      <Scoring
                        score={scoringProps.score}
                        redValue={scoringProps.redValue}
                        greenValue={scoringProps.greenValue}
                      />
                    </div>
                  </div>
                )}

                {this.state.debtorDetails.debtorRef && (
                  <div className="l-debtor__table-row c-table__row">
                    <div className="c-table__cell">
                      {TranslationService.translateModule('DebtorRef', this.props.module.name)}
                    </div>
                    <div className="c-table__cell">{this.state.debtorDetails.debtorRef}</div>
                  </div>
                )}

                {showDebtorId && (
                  <div className="l-debtor__table-row c-table__row">
                    <div className="c-table__cell">
                      {TranslationService.translateModule('Id', this.props.module.name)}
                    </div>
                    <div className="c-table__cell">{this.state.debtorDetails.identNo}</div>
                  </div>
                )}

                <div className="l-debtor__table-row c-table__row">
                  <div className="c-table__cell">
                    {TranslationService.translateModule('PhoneNo', this.props.module.name)}
                  </div>
                  <div className="c-table__cell">
                    <a href={'tel:' + this.state.debtorDetails.phone}>
                      {this.state.debtorDetails.phone}
                    </a>
                  </div>
                </div>

                <div className="l-debtor__table-row c-table__row">
                  <div className="c-table__cell">
                    {TranslationService.translateModule('Address', this.props.module.name)}
                  </div>

                  <div className="c-table__cell">
                    {this.state.debtorDetails.address.length > 0 && (
                      <>
                        {this.state.debtorDetails.address} <br />
                      </>
                    )}
                    {this.state.debtorDetails.address2.length > 0 && (
                      <>
                        {this.state.debtorDetails.address2} <br />
                      </>
                    )}
                    {this.state.debtorDetails.city.length > 0 &&
                      `${this.state.debtorDetails.postalCode}`}
                    {this.state.debtorDetails.postalCode.length > 0 &&
                      ` ${this.state.debtorDetails.city}`}
                  </div>
                </div>
              </div>

              {!!this.state.debtorDetails.totalDebt && (
                <div className="l-debtor__summary">
                  <div className="l-debtor__summary-box c-box c-box--5">
                    <div className="c-box__amount">
                      <span className="c-box__amount-value">
                        {CurrencyParser.toLocaleString(this.state.debtorDetails.totalDebt)}
                      </span>
                      <span className="c-box__currency">{this.props.currency}</span>
                    </div>
                    <div className="c-box__desc">
                      {TranslationService.translateModule(
                        'TotalDebtOnAllActiveCases',
                        this.props.module.name
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="l-debtor__links">
              {!!this.state.debtorDetails.activeCases && (
                <div className="l-debtor-links">
                  <Button
                    variant={BUTTON_VARIANTS.TRANSPARENT}
                    className="color-1 font--color-2 font--700"
                    size={BUTTON_SIZE.MD}
                    label={`${TranslationService.translateModule(
                      'ActiveCases',
                      this.module.name
                    )} (${this.state.debtorDetails.activeCases})`}
                    onClick={() => {
                      this.props.history.push(
                        `/case-list?debtorNumber=${this.state.debtorDetails.domainId}`
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </article>
    );
  }

  private loadDebtorDetails = async () => {
    if (this.hasRequiredParams()) {
      let debtorDetails: IDebtorDetails = {} as IDebtorDetails;
      if (this.isInCaseContext()) {
        debtorDetails = await DebtorDetailsService.getDebtorDetails(
          this.props.routeParameters.id as string
        );
      } else if (this.isInArmInvoiceContext()) {
        debtorDetails = await DebtorDetailsService.getArmDebtorDetails({
          invoiceNo: this.props.routeParameters.invoiceNo as string,
          creditorNo: this.props.routeParameters.creditorNo as string,
          accountNo: this.props.routeParameters.accountNo as string,
        });
      } else if (this.isInCaseModalContext()) {
        debtorDetails = await DebtorDetailsService.getDebtorDetails(this.props.caseId as string);
      } else if (this.isInArmModalContext()) {
        debtorDetails = await DebtorDetailsService.getArmDebtorDetails({
          invoiceNo: this.props.invoiceNo as string,
          creditorNo: this.props.creditorNo as string,
          accountNo: this.props.accountNo as string,
        });
      } else if (this.isInArmAgeAnalysisModalContext()) {
        debtorDetails = await DebtorDetailsService.getAgeAnalysisDebtorDetails(
          this.props.debtorNo as string,
          this.props.creditorNo as string
        );
      }

      this.setState({
        debtorDetails,
        hasAccessToModule: true,
        isDataLoaded: true,
      });
    }
  };

  private hasRequiredParams = () =>
    this.isInCaseContext() ||
    this.isInArmInvoiceContext() ||
    this.isInCaseModalContext() ||
    this.isInArmModalContext() ||
    this.isInArmAgeAnalysisModalContext();
  private isInCaseContext = () => this.props.routeParameters && this.props.routeParameters.id;
  private isInArmInvoiceContext = () =>
    this.props.routeParameters &&
    this.props.routeParameters.invoiceNo &&
    this.props.routeParameters.accountNo &&
    this.props.routeParameters.creditorNo;
  private isInCaseModalContext = () => !!this.props.caseId;
  private isInArmModalContext = () =>
    this.props.creditorNo && this.props.accountNo && this.props.invoiceNo;
  private isInArmAgeAnalysisModalContext = () => this.props.debtorNo && this.props.creditorNo;
}

const Scoring = (props: { score?: number; redValue: number; greenValue: number }) => {
  const scoringClass = props.score
    ? props.score < props.redValue
      ? 'red'
      : props.score > props.greenValue
      ? 'green'
      : 'yellow'
    : 'unknown';

  return (
    <div className={`scoring ${scoringClass}`} title={props.score ? props.score.toString() : ''} />
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    locale: state.currentCultureCode,
    currency: state.currency,
  };
};

export default connect(mapStateToProps)(withRouter(DebtorDetails));
